import React from 'react';

const Home = React.lazy(() => import('./models/Home'))
const membre = React.lazy(() => import('./models/Member'))
const wallet = React.lazy(() => import('./models/Wallet'))
const investors = React.lazy(() => import('./models/Investor'))
const addinvestor = React.lazy(() => import('./models/Addinvestor'))
const addinventement = React.lazy(() => import('./models/Investiment'))
const updateInvestor = React.lazy(() => import('./models/UpdateInvestor'))

const routes = [
    { path: '/', exact: true, name:'Home' },
    {path: '/home', name: 'Home', element: Home},
    {path: '/member', name: 'Membre', element: membre},
    {path: '/wallet', name: 'Wallet', element: wallet},
    {path: '/investors', name: 'Investors', element: investors},
    {path: '/addInvestors', name: 'AddInvestors', element: addinvestor},
    {path: '/invetiment', name: 'AddInvestement', element: addinventement},
    {path: '/updateInvestors/:idinvestors', name: 'UpdateInvestors', element: updateInvestor}
]

export default routes