import React from "react";
import { Chart } from "chart.js";

class PieChart extends React.Component {

    constructor(props) {
        super(props);
        this.pieChart = React.createRef();
    }

    componentDidUpdate() {
        // const lab = ['00:00', '02:00', '04:00', '06:00', '08:00', '10:00', '12:00', '14:00', '16:00', '18:00', '20:00', '22:00',];
        const dt = [20, 20]
        // this.myChart.data.labels = lab;
        this.myChart.data.datasets[0].data = dt;
        this.myChart.update();
    }

    componentDidMount() {
        this.myChart = new Chart(this.pieChart.current, {
            type: 'doughnut',
            data: {
                datasets: [
                {
                    data: this.props.dt,
                    backgroundColor: [ 'rgba(19, 81, 245, 1)', 'rgba(255, 45, 82, 1)', ], 
                },
                ],
            },
            
            options: {
                legend: {
                    display: false,
                },
                tooltips: {
                    enabled: false,
                },
                cutout:"80%",
                plugins: {
                    datalabels: {
                        formatter: (value) => {
                        return value + '%';
                        }
                    }
                }
            }
        });
    }

    render() {
        return <canvas ref={this.pieChart} width="400" height="200"></canvas>
    }
}

export default PieChart;