import React from "react";
import { Chart } from "chart.js";

class BarChart extends React.Component {
    constructor(props) {
        super(props);
        this.barChart = React.createRef();
    }

    componentDidUpdate() {
        
        // this.myChart.data.labels = lab;
        // this.myChart.data.datasets[0].data = data;
        this.myChart.update();
    }
    

    componentDidMount() {
        const data = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July'],
            datasets: [{
            label: 'Weekly Sales',
            data: [2500, 1200, 600, 900, 1200, 300, 900],
            backgroundColor: [
                'rgba(19, 81, 245, 1)'
            ],
            },{
            label: 'Weekly Sales',
            data: [1800, 1200, 600, 900, 120, 300, 900],
            backgroundColor: [
                'rgba(255, 45, 82, 1)',
            ],
            }]
            };

        this.myChart = new Chart(this.barChart.current, {
            type: 'bar',
            data,
            options: {
            plugins:{
                legend:{
                    display: false
                }
            },
            scales: {
                y: {
                beginAtZero: false,
                grid:{
                    display:true
                }
                },
                x: {
                beginAtZero: false,
                grid:{
                    display:false
                }
                }
            }
            }
        });
    }

    render() {
        return <canvas ref={this.barChart} width="400" height="200"></canvas>
    }
}

export default BarChart;