
import React from "react";
import AppContent from "../components/AppContent";
import {Menu} from "../components/index"

const DefaultLayout = () => {
    return (
        <div>
            <div>
                <Menu />
                <AppContent />
            </div>
        </div>
    )
}

export default DefaultLayout;