import React from "react";
import { Chart } from "chart.js/auto";

class LineChart extends React.Component {


    constructor(props) {
        super(props);
        this.lineChart = React.createRef();
    }

    componentDidUpdate() {
        const lab = ['00:00', '02:00', '04:00', '06:00', '08:00', '10:00', '12:00', '14:00', '16:00', '18:00', '20:00', '22:00',];
        const dt = [1900, 2000, 1900, 2100, 1950, 2200, 2000, 1990, 1800, 1950, 1850, 2000]
        this.myChart.data.labels = lab;
        this.myChart.data.datasets[0].data = dt;
        this.myChart.update();
    }

    componentDidMount() {
        this.myChart = new Chart(this.lineChart.current, {
            type: 'line',
            data: {
                labels: this.props.lab,
                datasets: [
                    {
                        backgroundColor: 'rgba(19, 81, 245, 0.4)',
                        borderColor: 'rgb(19, 81, 245)',
                        data: this.props.dt,
                        fill: 'start',
                        tension: .5
                    },
                ],
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    yAxes: {
                        ticks: {
                            min: 1800,
                            Max: 12200,
                            stepSize: 100
                        },
                        position: 'right',
                        grid: {
                            display: true,
                        },
                    },
                    y: {
                        display: false,
                        grid: {
                            display: true,
                        },
                    },
                    x: {
                        beginAtZero: false,
                        grid: {
                            display: false
                        }
                    }
                }
            }
        });
    }

    render() {
        return (
            <canvas ref={this.lineChart} width={400} height={90} />
        )
    }

}

export default LineChart;