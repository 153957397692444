import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BarChart from '../asset/BarChart';
// import '../asset/LineChart';
import LineChart from '../asset/LineChart';
import PieChart from '../asset/PieChart';


function getRandomArray(numItems) {
	let names = "'00:00''02:00''04:00''06:00''08:00''10:00''12:00''14:00''16:00''18:00''20:00''22:00'";
	let data = [];
	for (var i = 0; i < numItems; i++) {
		data.push({
			label: names[i],
			value: Math.round(20 + 80 * Math.random())
		});
	}
	return data;
}

function getData() {
	let data = [];

	data.push({
		title: 'line',
		data: getRandomArray(1000)
	});

	return data;
}
class Home extends React.Component {
	// constructor(props) {
	// 	super(props);

	// 	this.state = {
	// 		data: getData(),
	// 	};
	// }

	// componentDidMount() {
	// 	window.setInterval(() => {
	// 		this.setState({
	// 			data: getData()
	// 		})
	// 	}, 5000)
	// }

		render() {
			return (

				<>
					
					<div className="mainDashboard px-4 py-4">
						<div className="shadowBox"></div>
						<div className="container-fluid">
							<div className="row">
								<div className="col-3">
									{/* Revenue Card */}
									<div className="revenueCard mb-3 bg-white text-center">
										<div className="headingBar fw-bold py-4"><span className="fw-bold">Mon, 19 September, 2022</span></div>
										<div className="p-3">
											<div className="revenueDetails mb-3 d-flex">
												<div className="fw-bold">Income</div>
												<div className="mx-auto fw-bold">-</div>
												<div>$3400.00</div>
											</div>
											<div className="revenueDetails mb-3 d-flex">
												<div className="fw-bold">Income</div>
												<div className="mx-auto fw-bold">-</div>
												<div>$3400.00</div>
											</div>
											<div className="revenueDetails d-flex">
												<div className="fw-bold">Income</div>
												<div className="mx-auto fw-bold">-</div>
												<div>$3400.00</div>
											</div>
										</div>
									</div>
									{/* Revenue Card  */}
									<div className="revenueCard mb-3 bg-white text-center">
										<div className="headingBar fw-bold py-4"><span className="fw-bold">Mon, 19 September, 2022</span></div>
										<div className="p-3">
											<div className="revenueDetails mb-3 d-flex">
												<div className="fw-bold">Income</div>
												<div className="mx-auto fw-bold">-</div>
												<div>$3400.00</div>
											</div>
											<div className="revenueDetails mb-3 d-flex">
												<div className="fw-bold">Income</div>
												<div className="mx-auto fw-bold">-</div>
												<div>$3400.00</div>
											</div>
											<div className="revenueDetails d-flex">
												<div className="fw-bold">Income</div>
												<div className="mx-auto fw-bold">-</div>
												<div>$3400.00</div>
											</div>
										</div>
									</div>
									{/* Revenue Card  */}
									<div className="revenueCard mb-3 bg-white text-center">
										<div className="headingBar fw-bold py-4"><span className="fw-bold">Mon, 19 September, 2022</span></div>
										<div className="p-3">
											<div className="revenueDetails mb-3 d-flex">
												<div className="fw-bold">Income</div>
												<div className="mx-auto fw-bold">-</div>
												<div>$3400.00</div>
											</div>
											<div className="revenueDetails mb-3 d-flex">
												<div className="fw-bold">Income</div>
												<div className="mx-auto fw-bold">-</div>
												<div>$3400.00</div>
											</div>
											<div className="revenueDetails d-flex">
												<div className="fw-bold">Income</div>
												<div className="mx-auto fw-bold">-</div>
												<div>$3400.00</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-9">
	
									{/* Total Income Chart */}
									<div className="row m-0 mb-5">
										<div className="position-relative bg-white px-5 py-4 rounded-5">
											<div className="topBar">
												<div className="row m-0 mb-4">
													<div className="col-4 linechartHeading">
														<span className="fw-bold">Transaction Overview</span>
														<span className="gray-text d-block">$1,959.34</span>
													</div>
													<div className="col-8 d-flex my-auto justify-content-end">
														<div className="chartDates my-auto">
															<span>14.12.2019</span><span className="bg-white">-</span><span>23.01.2020</span>
														</div>
														<div>
															<div className="chartlinks ms-4 navbar-expand">
																<ul className="navbar-nav">
																	<li className="nav-item fw-bold active">24H</li>
																	<li className="nav-item fw-bold">1W</li>
																	<li className="nav-item fw-bold">1M</li>
																	<li className="nav-item fw-bold">1Y</li>
																	<li className="nav-item fw-bold">5Y</li>
																	<li className="nav-item fw-bold">ALL</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
												{/* <canvas ref={this.lineChart} width={400} height={90}></canvas>	 */}
												<LineChart />
											</div>
										</div>
									</div>
	
									{/* Per Month Income Details  */}
									<div className="row m-0 mb-5">
										<div className="col-8 ps-0">
											<div className="bg-white p-5 py-4 position-relative rounded-5">
												<div className="row m-0 mb-4 topBar">
													<div className="col-6 p-0">
														<span className="barChart fw-bold">2022 Per Month  Tiva Spendings</span>
													</div>
													<div className="col-6 p-0">
														<div className="barChartTag justify-content-end d-flex">
															<div className="d-flex my-auto fw-bold"><div className="red me-3 my-auto"></div>Vendors, Agents and Investors</div>
															<div className="d-flex ms-4 my-auto fw-bold"><div className="blue me-3 my-auto"></div>Computers Program</div>
														</div>
													</div>
												</div>
												<BarChart />
											</div>
										</div>
										<div className="col-4 pe-0">
											<div className="bg-white py-4 px-5 position-relative rounded-5 topBar h-100">
												<div className="chartHeading">
													<span className="d-block fw-bold small-font">Tiva Spendings</span>
													<span >September, 2022</span>
												</div>
												<div className="pieChart mt-5">
													<div className="barChartTag">
														<div className="d-flex mb-2 my-auto fw-bold"><div className="red me-3 my-auto"></div>Hospital Claims</div>
														<div className="d-flex my-auto fw-bold"><div className="blue me-3 my-auto"></div>Salaries</div>
													</div>
													<PieChart />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)
		}
	
}


export default Home;
