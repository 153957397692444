import React from "react";
import { Link } from "react-router-dom";
import SCLOGO from "../../assets/css1/img/tiva-logo.png";
import "../../assets/css1/css/app.css";
import "../../assets/css1/css/bootstrap-grid.min.css";
import "../../assets/css1/icons/boxicons-2/css/boxicons.min.css";

const Signup = () => {
    return (
        <React.Fragment>
            <div className="auth-multi-layout">
                <div className="auth-box">
                    <div className="auth-header">
                        <div className="auth-header-logo">
                            <img src={SCLOGO} alt="" className="auth-header-logo-img" />
                        </div>
                        <h1 className="auth-header-title">Create Account</h1>
                        <p className="auth-header-subtitle">
                        Create your account to enter the system
                        </p>
                    </div>
                    <div className="auth-body">
                        <form className="auth-form-validation">
                            <div className="input-field">
                                <label htmlFor="full-name" className="input-label">
                                    Full Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="full-name"
                                    placeholder="Jhon doe"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="email" className="form-label">
                                    Email address
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    placeholder="example@gmail.com"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="password" className="input-label">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    className="form-control"
                                    placeholder="Password"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="password" className="input-label">
                                Confirm your password
                                </label>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    className="form-control"
                                    placeholder="Password"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <button type="submit" className="btn-submit">
                                Create account
                            </button>
                        </form>
                        <p className="text-center">
                            Already have an account?{" "}
                            <Link to={"/signin"} className="link-text-center">
                                Signin instead
                            </Link>
                        </p>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
};

export default Signup;
