import React, { Suspense } from 'react';
import "react-toastify/dist/ReactToastify.css";
import { HashRouter , Routes, Route} from 'react-router-dom';
import DefaultLayout from './Layout/DefaultLayout';
import Login from './models/login/Login';
import Signup from './models/login/Signup';
import ForgotPassword from './models/login/ForgotPassword';

class App extends React.Component {
 render() {
  return (
    <>
      <HashRouter>
         <Suspense>
         <Routes>
            <Route exact path='/' name="login" element={<Login />} />
            <Route exact path='/signin' name="login" element={<Login />} />
            <Route exact path='/signup' name="signup" element={<Signup />} />
            <Route exact path='/forgot-password' name="forgotpassword" element={<ForgotPassword />} />
            <Route exact path='*' name="Home" element={<DefaultLayout />} />
         </Routes>
         </Suspense>
      </HashRouter>
    </>
   );
 
 }  
}

export default App;
