import React, { useRef, useState, useEffect, useContext } from "react";
import AuthContext from "./context/AunthLogin";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import SCLOGO from "../../assets/css1/img/tiva-logo.png";
import "../../assets/css1/css/app.css";
import "../../assets/css1/css/bootstrap-grid.min.css";
import "../../assets/css1/icons/boxicons-2/css/boxicons.min.css";


const Login = () => {
  const { setAuth } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setErrMsg('');
  }, [username, password])

  const loginSubmit = async (e) => {
    e.preventDefault();

    var data = JSON.stringify({
      "username": username,
      "password": password
    });

    var config = {
      method: 'post',
      url: global.Config.api.url + '/auth/login',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        if (response.data.status == 200) {
          const accessToken = response.data.token;
          const roles = response.data.roles;
          setAuth({ username, password, accessToken, roles })
          setUsername('');
          setPassword('');
          setSuccess(true);
          localStorage.setItem("token", response.data.token);
        } else {
          // setErrMsg(response.data.message);
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        // setErrMsg(error.message);
        toast.error(error.message);
        errRef.current.focus();
      });
  }

  return (

    <React.Fragment>
      {success ? (
        <Navigate to='/home' replace />
      ) : (
        <div className="auth-multi-layout">
          <div className="auth-box">
            <div className="auth-header">
              <div className="auth-header-logo">
                <img src={SCLOGO} alt="" className="auth-header-logo-img" />
              </div>
              <p className="auth-header-subtitle">
                Login to your account and start work
              </p>
            </div>
            <div className="auth-body">
              <div className="auth-form-validation">
                <div>
                  < ToastContainer />
                </div>
                <div className="input-field">
                  <label htmlFor="email" className="input-label">
                    Username or email
                  </label>

                  <input type="text" className="form-control" id="email" placeholder="example@gmail.com"
                    autoComplete="off"
                    required name="username"
                    ref={userRef} onChange={(e) => setUsername(e.target.value)}
                    value={username}
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="password" className="input-label">
                    Password
                  </label>
                  <input type="password" name="password" id="password" className="form-control" placeholder="Password"
                    autoComplete="off"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </div>
                <div className="input-field">
                  <div className="checkbox">
                    <label>
                      <input id="login-remember" type="checkbox" name="remember" /> Remember me
                    </label>
                  </div>
                </div>

                <div className="flex-end">
                  <Link to={"/forgot-password"} className="link-end">
                    Forgot password?
                  </Link>
                </div>
                <button type="submit" className="btn-submit" onClick={loginSubmit}>
                  Sign in
                </button>
              </div>
              <p className="text-center">
                Don't have an account!{" "}
                <Link to={"/signup"} className="link-text-center">
                  Sign Up Here
                </Link>
              </p>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Login;
