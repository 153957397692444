import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Context from "@mui/base/TabsUnstyled/TabsContext";
import '../assets/css/bootstrap.min.css';
import '../assets/css/amsify.select.css';
import '../assets/css/style.css';
import '../assets/css/color.css';
import CollapsibleTable from './table/Tinvestor';
// import CollapsibleTable from './table/Tb';

function Wallet() {

    var data = '';
    const [listWallet, setListWallet] = useState([])
    const [listWalletTotal, setlistWalletTotal] = useState([])
    useEffect(() => {
       List();
    }, []);
 
     const List = () => {
         var config = {
             method: 'get',
             url: global.Config.api.url+'/investor/investiment/load-all',
             headers: {
                 'Accept': 'application/json',
                 'Authorization': 'Bearer '+global.Config.api.token
             },
             data: data
         };
     
         axios(config)
             .then(function (response) {
                 //console.log(JSON.stringify(response.data));
                 setListWallet(response.data.data.liste);
                 setlistWalletTotal(response.data.data.total)
             })
             .catch(function (error) {
                 console.log(error);
             });
 
     }

     const getTotals = (array) => {
        var sommes = 0;

        array.forEach(element => {
            sommes+=parseFloat(element.amount);
        });

        return sommes;
        
     }
     const getNames = (source) =>{
        if (source == "Vouchers")  return 'Vouchers';
        if (source == "USA PLATE FORME") return "USA PLATE FORME";
         return '';
     }
    return (
        <>
            <div className="mainDashboard">
                <div className="shadowBox"></div>
                <div className="container-fluid px-0">

                     {/* Table Header Bar */}
                    <div className="row m-0 px-4 defaultPadding">
                        <div className="col-5">
                            <div className="pagesHeading d-flex">
                                <div className="pageIcon item-center bgDepartment">
                                    <img src="../assets/img/department.svg" alt="" />
                                </div>
                                <div className="my-auto ms-3 d-flex">
                                    <div>
                                        <span className="small-font">Key Investors</span>
                                        <span className="tableHeading d-flex position-relative">
                                            Wallet
                                            <div className="dropdown categorydrop">
                                                <button className="btn p-0" type="button" id="categoryMenu" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-caret-down ms-3 fs-5"></i></button>
                                                <ul className="dropdown-menu" aria-labelledby="categoryMenu">
                                                    <h5>Key Investors Categories</h5>
                                                    <li><Link className="dropdown-item active" to="/wallet">Wallet</Link></li>
                                                    <li><Link className="dropdown-item" to="/investors">Investors</Link></li>
                                                </ul>
                                            </div>
                                        </span>
                                    </div>
                                    <div className="mt-3 ms-4">
                                        <button className="btn shadow-btn iconBtn item-center bg-white"><img src="../assets/img/pin.svg" alt="" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-7">
                            <div className="d-flex mt-3 justify-content-end">
                                <div className="findBox bg-white ms-3">
                                    <form action="">
                                        <button className="btn"><i className="fa-solid fa-magnifying-glass"></i></button>
                                        <input type="text" placeholder="Search Here...." />
                                    </form>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><i className="fa-solid fa-gear"></i><i className="fa-solid fa-caret-down ms-3"></i></button>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><i className="fa-solid fa-list"></i><i className="fa-solid fa-caret-down ms-3"></i></button>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><i className="fa-solid fa-rotate-right"></i></button>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><i className="fa-regular fa-pen-to-square"></i></button>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><img src="../assets/img/data.svg" alt="" /></button>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><img src="../assets/img/solar.svg" alt="" /></button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="container mt-3">
                        <div className="row m-0 px-2">
                            
                            <div className="col-3 px-2 mb-4 mx-auto">
                                <div className="alldetailCard liveCard">
                                    <div className="my-auto">
                                        <img src="../assets/img/cardTwo.svg" alt="" />
                                    </div>
                                    <div className="text-center bg-white live bar">
                                        <span className="d-block fw-bold">Main Balance<span className="d-block mt-1 primaryFont fw-bold">${getTotals(listWalletTotal)}</span></span>
                                    </div>
                                </div>
                            </div>
                            {listWalletTotal.map((totals,i) =>(
                            <div className="col-3 px-2 mb-4 ms-auto" key={i+1}>
                                <div className="alldetailCard liveCard">
                                    <div className="my-auto">
                                        <img src="../assets/img/cardTwo.svg" alt="" />
                                    </div>
                                    <div className="text-center bg-white live bar">
                                        <span className="d-block">{getNames(totals.source)}<span className="d-block mt-1 primaryFont fw-bold">{totals.amount}</span></span>
                                    </div>
                                </div>
                            </div>
                            ))
                             }
                        </div>
                    </div>

                    {/* Table Box  */}
                    <div className="row m-0">
                        <div className="col-12 px-0">
                            <CollapsibleTable rows={listWallet} />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Wallet;