import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './config/Config';
import { AunthLogin } from './models/login/context/AunthLogin';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AunthLogin>
       <App />
    </AunthLogin>   
);

