import React, { useState, useEffect, useRef, useContext } from "react";
import AuthContext from "./login/context/AunthLogin";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import '../assets/css/bootstrap.min.css';
import '../assets/css/amsify.select.css';
import '../assets/css/style.css';
import '../assets/css/color.css';
import {ToastContainer ,toast} from "react-toastify";

function Addinvestor() {
    var data = '';
    const { setAuth } = useContext(AuthContext);
    const [listCountri, setListCountri] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
        const userRef = useRef();
        const errRef = useRef();

        const [username, setUsername] = useState('');
        const [middlename, setMiddlename] = useState('');
        const [lastname, setLastname] = useState('');
        const [dateBith, setDateBith] = useState('');
        const [phone, setPhone] = useState('');
        const [genre, setGenre] = useState('');
        const [email, setEmail] = useState('');
        const [citizenship, setCitizenship] = useState('');
        const [street, setStreet] = useState('');
        const [stateprovince, setStateprovince] = useState('');
        const [city, setCity] = useState('');
        const [zipcode, setZipcode] = useState('');
        const [govbranch, setGovbranch] = useState('');
        const [govstreet, setGovstreet] = useState('');
        const [govzipcode, setGovzipcode] = useState('');
        const [govcity, setGovcity] = useState('');
        const [country, setCountry] = useState('');
        const [govphone, setGovphone] = useState('');
        const [message, setMessage] = useState('');
        const [categori, setCategori] = useState('');
        useEffect(() => {
            userRef.current.focus();
        }, [])

        useEffect(() => {
            setErrMsg('');
        }, [username, middlename,lastname,dateBith,phone,genre,email,citizenship,street,stateprovince,
           city,zipcode,govbranch,govstreet,govzipcode,govcity,country,govphone,message,categori
        ])

    useEffect(() => {
        countri();
        category();
    }, []);

    const countri = () => {
        var config = {
            method: 'get',
            url: global.Config.api.url +'/configuration/country/load',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + global.Config.api.token
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setListCountri(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const category = () => {
        var config = {
            method: 'get',
            url: global.Config.api.url + '/configuration/category/load',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + global.Config.api.token
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setListCategory(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const AddNewinvestor = async (e) => {
        e.preventDefault();

        var data = JSON.stringify({
            "id_country": country,
            "name": username,
            "middle_name": middlename,
            "last_name": lastname,
            "date_of_birth": dateBith,
            "phone": phone,
            "gender": genre,
            "email": email,
            "citizenship": citizenship,
            "adresse_street": street,
            "state_province": stateprovince,
            "city": city,
            "codezip": zipcode,
            "gov_branch_name": govbranch,
            "gov_street": govstreet,
            "gov_zip_code": govzipcode,
            "gov_choose_country": country,
            "gov_phone": govphone,
            "gov_city": govcity,
            "gov_note": message,
            "id_category": categori
        });

        var config = {
            method: 'post',
            url: global.Config.api.url + '/customer/create',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + global.Config.api.token,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                if(response.data.status==200){
                    const accessToken = response.data.accessToken;
                    const roles = response.data.roles;
                    setAuth({ username, country, middlename,lastname,dateBith,phone,genre,email,
                        citizenship,street,stateprovince,city,zipcode,govbranch,govstreet,govzipcode
                        ,govphone,govcity,message,categori,accessToken, roles })
                    setUsername('');
                    setMiddlename('');
                    setLastname('');
                    setDateBith('');
                    setPhone('');
                    setGenre('');
                    setEmail('');
                    setCitizenship('');
                    setStreet('');
                    setStateprovince('');
                    setCity('');
                    setZipcode('');
                    setGovbranch('');
                    setGovstreet('');
                    setGovzipcode('');
                    setGovphone('');
                    setGovcity('');
                    setMessage('');
                    setCategori('');
                    setSuccess(true);
                    toast.success(`Investor added success`);
                    localStorage.setItem("id_created",response.data.data.id);
                }else{
                    toast.error(`Echec to add Investor, please verify elements after submit`);
                    // setErrMsg(response.data.message);
                }
            })
            .catch(function (error) {
                toast.error(error.message);
                // setErrMsg(error.message);   
                errRef.current.focus();
            });
    }

    const options = [
        {
            label: 'Male',
            value: 'Male'
        },
        {
            label: 'Female',
            value: 'Female'
        }
    ]
    return (
        <>
            { success ? (
                  <Navigate to='/invetiment' replace />
                  
            ) : (
            <div className="mainDashboard">
                <div className="shadowBox"></div>
                <div className="container-fluid px-0">

                    {/* Table Header Bar */}
                    <div className="row m-0 px-4 defaultPadding">
                        <div className="col-5">
                            <div className="pagesHeading d-flex">
                                <div className="pageIcon item-center bgDepartment">
                                    <img src="../assets/img/ipdIcon.svg" alt="" />
                                </div>
                                <div className="my-auto ms-3 d-flex">
                                    <div>
                                        <span className="small-font">Key Investors</span>
                                        <span className="tableHeading d-flex position-relative">
                                            Create Investor Account
                                            <div className="dropdown categorydrop">
                                                <button className="btn p-0" type="button" id="categoryMenu" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-caret-down ms-3 fs-5"></i></button>
                                                <ul className="dropdown-menu" aria-labelledby="categoryMenu">
                                                    <h5>Key Investors Categories</h5>
                                                    <li><Link className="dropdown-item" to="/wallet">Wallet</Link></li>
                                                    <li><Link className="dropdown-item active" to="/investors">Investors</Link></li>
                                                </ul>
                                            </div>
                                        </span>
                                    </div>
                                    <div className="mt-3 ms-4">
                                        <button className="btn shadow-btn iconBtn item-center bg-white"><img src="../assets/img/pin.svg" alt="" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-7">
                            <div className="d-flex mt-3 justify-content-end">
                                <div className="findBox bg-white ms-3">
                                    <form action="">
                                        <button className="btn"><i className="fa-solid fa-magnifying-glass"></i></button>
                                        <input type="text" placeholder="Search Here...." />
                                    </form>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><i className="fa-solid fa-gear"></i><i className="fa-solid fa-caret-down ms-3"></i></button>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><i className="fa-solid fa-list"></i><i className="fa-solid fa-caret-down ms-3"></i></button>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><i className="fa-solid fa-rotate-right"></i></button>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><i className="fa-regular fa-pen-to-square"></i></button>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><img src="../assets/img/data.svg" alt="" /></button>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><img src="../assets/img/solar.svg" alt="" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 p-5 bg-white profilePage">
                        <div>
                           < ToastContainer />
                        </div>
                        <div className="col-12">
                            <h5 className="fs-5 mb-4">Identity:</h5>
                        </div>
                        <div className="row m-0 px-5">
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Name</label>
                                    <input type="text" className="w-100" autoComplete="off" name="username" placeholder="" 
                                    ref={userRef} onChange={(e) => setUsername(e.target.value)}
                                    value={username} required/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Middle Name</label>
                                    <input type="text" className="w-100" autoComplete="off" name="middlename" placeholder="" 
                                    ref={userRef} onChange={(e) => setMiddlename(e.target.value)} value={middlename}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Last Name</label>
                                    <input type="text" name="lastname" autoComplete="off" className="w-100" 
                                    ref={userRef} onChange={(e) => setLastname(e.target.value)} value={lastname}/>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0 px-5">
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Date of Birth</label>
                                    <input type="date" name="dateBith" className="w-100" 
                                    ref={userRef} onChange={(e) => setDateBith(e.target.value)} value={dateBith}/>
                                    {/* </select> */}
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Phone</label>
                                    <input type="text" name="phone" autoComplete="off" className="w-100" 
                                    ref={userRef} onChange={(e) => setPhone(e.target.value)} value={phone}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Gender</label>
                                    <select className="form-select" name="genre" aria-label="Default select example" 
                                       ref={userRef} onChange={(e) => setGenre(e.target.value)}>
                                        <option value="">Choose gender</option>
                                        {options.map((option,i) =>(
                                        <option key={i} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Email</label>
                                    <input type="email" name="email" autoComplete="off" className="w-100" placeholder="" 
                                    ref={userRef} onChange={(e) => setEmail(e.target.value)} value={email}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Citizenship</label>
                                    <input type="text" name="citizenship" className="w-100" placeholder="" 
                                    ref={userRef} onChange={(e) => setCitizenship(e.target.value)} value={citizenship}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Category</label>
                                    <select className="form-select" name="categori" aria-label="Default select example"
                                        ref={userRef} onChange={(e) => setCategori(e.target.value)}>
                                            <option value="">Choose category</option>
                                        {listCategory.map((category, i) => (
                                            <option key={i} value={category.id}>{category.name}</option>
                                        ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-4">
                            <h5 className="fs-5 mb-4">Your Address</h5>
                        </div>
                        <div className="row m-0 mt-3 px-5">
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Street</label>
                                    <input type="text" name="street" autoComplete="off" className="w-100" placeholder="" 
                                    ref={userRef} onChange={(e) => setStreet(e.target.value)} value={street}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">City</label>
                                    <input type="text" name="city" autoComplete="off" className="w-100" placeholder="" 
                                    ref={userRef} onChange={(e) => setCity(e.target.value)} value={city}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">State/Province</label>
                                    <input type="text" name="stateprovince" autoComplete="off" className="w-100" placeholder="" 
                                    ref={userRef} onChange={(e) => setStateprovince(e.target.value)} value={stateprovince}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Zip Code</label>
                                    <input type="text" name="zipcode" autoComplete="off" className="w-100" placeholder="" 
                                    ref={userRef} onChange={(e) => setZipcode(e.target.value)} value={zipcode}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 my-4">
                            <h5 className="fs-5 mb-0">Government</h5>
                        </div>
                        <div className="row m-0 px-5">
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Gov. Branch Name</label>
                                    <input type="text" name="govbranch" className="w-100" placeholder="" 
                                    ref={userRef} onChange={(e) => setGovbranch(e.target.value)} value={govbranch}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Street</label>
                                    <input type="text" name="govstreet" className="w-100" placeholder="" 
                                    ref={userRef} onChange={(e) => setGovstreet(e.target.value)} value={govstreet}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Zip Code</label>
                                    <input type="text" name="govzipcode" className="w-100" placeholder="" 
                                    ref={userRef} onChange={(e) => setGovzipcode(e.target.value)} value={govzipcode}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">City</label>
                                    <input type="text" name="govcity" className="w-100" placeholder="" 
                                    ref={userRef} onChange={(e) => setGovcity(e.target.value)} value={govcity}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Choose Country</label>
                                    <select className="form-select" name="country" aria-label="Default select example"
                                    ref={userRef} onChange={(e) => setCountry(e.target.value)} value={country}>
                                          <option value="">Choose country</option>
                                        {listCountri.map((country, i) => (
                                            <option key={i} value={country.id}>{country.name}</option>
                                        ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Phone</label>
                                    <input type="text" name="govphone" className="w-100" placeholder="" 
                                    ref={userRef} onChange={(e) => setGovphone(e.target.value)} value={govphone}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="inputBox mb-3">
                                    <textarea name="message" ref={userRef} onChange={(e) => setMessage(e.target.value)} 
                                    value={message}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 mt-3 ms-auto me-5">
                            <button type="button" onClick={AddNewinvestor} className="btn w-100 btn-primary rounded-pill">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            )
            }
        </>
    );

}

export default Addinvestor;