import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../asset/LineChart';

function Menu() {

	const [toggle, setToggle] = useState(false);
	const handleClick = () => {
		setToggle(!toggle);
	}

	return (

		<>
			{/* Header */}
			<section id="header" className="bg-white">
				<div className="container-fluid px-5">
					<div className="row">
						<div className="col-12 my-auto d-flex py-4">
							<div className="logo my-auto">
								<img src="../assets/img/tiva-logo.png" alt="" />
							</div>
							<div className="homeLink my-auto mx-4"><Link to="/home" className="active fw-bold nav-link text-dark">Home</Link></div>
							<div className="searchBox my-auto d-flex">
								<div className="searchSelector">

									<div className="card-name card-form">
										<div className="input-wrapper">
											<div className="card-grp" onClick={handleClick}>
												<div className="card-input" id="cardInput"  >
												</div>
												<div className="arrow">
													<i className="fas fa-caret-down fa-lg"></i>
												</div>
											</div>

											<ul className="card-list" style={{ display: toggle ? 'block' : 'none' }}>
												<li><Link to="/home" className="nav-link p-0 text-dark"><img src="../assets/img/Maindashboard.png" className="align-middle me-3 bgDashboard" />Dashboard</Link></li>
												<li><Link to="/member" className="nav-link p-0 text-dark"><img src="../assets/img/profile.svg" className="align-middle me-3 bgProfile" />Customers</Link></li>
												<li><Link to="/wallet" className="nav-link p-0 text-dark"><img src="../assets/img/department.svg" className="align-middle me-3 bgDepartment" />Key Investors</Link></li>
											</ul>

										</div>
									</div>
								</div>
								<div className="searchInput h-100">
									<form action="" method="" className="h-100">
										<input type="text" className="my-auto" placeholder="Search Here...." />
										<button className="btn my-auto"><i className="fa-solid fa-magnifying-glass"></i></button>
									</form>
								</div>
							</div>
							<div className="profileBtn ms-3 my-auto ms-auto">
								<img src="../assets/img/profile.png" alt="" />
								<span className="profileStatus active"></span>
							</div>
						</div>
					</div>
				</div>
			</section>

		</>
	)

}


export default Menu;