import React, { useState, useEffect, useRef, useContext } from "react";
import AuthContext from "./login/context/AunthLogin";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import '../assets/css/bootstrap.min.css';
import '../assets/css/amsify.select.css';
import '../assets/css/style.css';
import '../assets/css/color.css';
import {ToastContainer ,toast} from "react-toastify";

function Investiment() {
    var data = '';
    const { setAuth } = useContext(AuthContext);

    const [listCountri, setListCountri] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const userRef = useRef();
    const errRef = useRef();

    const [amount, setAmount] = useState('');
    const [pourcent, setPourcent] = useState('');
    const [current, setCurrent] = useState('');
    const [datestart, setDatestart] = useState('');
    const [dateend, setDateend] = useState('');
    const [country, setCountry] = useState('');
    const [items, setItems] = useState([]);
    

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [amount, pourcent, current, datestart, dateend, items
    ])

    useEffect(() => {
        countri();
    }, []);

    const countri = () => {
        var config = {
            method: 'get',
            url: global.Config.api.url +'/configuration/country/load',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + global.Config.api.token
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setListCountri(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const AddNewinvestor = async (e) => {
        e.preventDefault();

        var data = JSON.stringify({
            "id_country": country,
            "id_customer": localStorage.getItem('id_created'),
            "pourcent": pourcent,
            "amount": amount,
            "currency": current,
            "date_start": datestart,
            "date_end": dateend,
            "contrat_file": ""
        });

        var config = {
            method: 'post',
            url: global.Config.api.url + '/investor/investiment/create',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + global.Config.api.token,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                if (response.data.status == 200) {
                    const accessToken = response.data.accessToken;
                    const roles = response.data.roles;
                    setAuth({ amount, pourcent, current, datestart, dateend, accessToken, roles })
                    setAmount('');
                    setPourcent('');
                    setCurrent('');
                    setDatestart('');
                    setDateend('');
                    setSuccess(true);
                    // setErrMsg('');
                    toast.success(`Investiment added success`);
                    localStorage.setItem("token", response.data.token);
                } else {
                    // setErrMsg(response.data.message);
                    toast.error(`Echec to add Investiment, please verify elements after submit`);
                }
            })
            .catch(function (error) {
                // setErrMsg(error.message);
                toast.error(error.message);
                errRef.current.focus();
            });
    }

    const options = [
        {
            label: 'USD',
            value: 'USD'
        }
        // {
        //     label: 'CDF',
        //     value: 'CDF'
        // }
    ]
    return (
        <>
            {success ? (
                <Navigate to='/investors' replace />
            ) : (
                <div className="mainDashboard">
                    <div className="shadowBox"></div>
                    <div className="container-fluid px-0">

                        {/* Table Header Bar */}
                        <div className="row m-0 px-4 defaultPadding">
                            <div className="col-5">
                                <div className="pagesHeading d-flex">
                                    <div className="pageIcon item-center bgDepartment">
                                        <img src="../assets/img/ipdIcon.svg" alt="" />
                                    </div>
                                    <div className="my-auto ms-3 d-flex">
                                        <div>
                                            <span className="small-font">Key Investors</span>
                                            <span className="tableHeading d-flex position-relative">
                                                Create Investor Account
                                                <div className="dropdown categorydrop">
                                                    <button className="btn p-0" type="button" id="categoryMenu" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-caret-down ms-3 fs-5"></i></button>
                                                    <ul className="dropdown-menu" aria-labelledby="categoryMenu">
                                                        <h5>Key Investors Categories</h5>
                                                        <li><Link className="dropdown-item" to="/wallet">Wallet</Link></li>
                                                        <li><Link className="dropdown-item active" to="/investors">Investors</Link></li>
                                                    </ul>
                                                </div>
                                            </span>
                                        </div>
                                        <div className="mt-3 ms-4">
                                            <button className="btn shadow-btn iconBtn item-center bg-white"><img src="../assets/img/pin.svg" alt="" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-7">
                                <div className="d-flex mt-3 justify-content-end">
                                    <div className="findBox bg-white ms-3">
                                        <form action="">
                                            <button className="btn"><i className="fa-solid fa-magnifying-glass"></i></button>
                                            <input type="text" placeholder="Search Here...." />
                                        </form>
                                    </div>
                                    <div className="ms-3">
                                        <button className="btn shadow-btn h-100 bg-white"><i className="fa-solid fa-gear"></i><i className="fa-solid fa-caret-down ms-3"></i></button>
                                    </div>
                                    <div className="ms-3">
                                        <button className="btn shadow-btn h-100 bg-white"><i className="fa-solid fa-list"></i><i className="fa-solid fa-caret-down ms-3"></i></button>
                                    </div>
                                    <div className="ms-3">
                                        <button className="btn shadow-btn h-100 bg-white"><i className="fa-solid fa-rotate-right"></i></button>
                                    </div>
                                    <div className="ms-3">
                                        <button className="btn shadow-btn h-100 bg-white"><i className="fa-regular fa-pen-to-square"></i></button>
                                    </div>
                                    <div className="ms-3">
                                        <button className="btn shadow-btn h-100 bg-white"><img src="../assets/img/data.svg" alt="" /></button>
                                    </div>
                                    <div className="ms-3">
                                        <button className="btn shadow-btn h-100 bg-white"><img src="../assets/img/solar.svg" alt="" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0 p-5 bg-white profilePage">
                            <div>
                            < ToastContainer />
                            </div>
                            <div className="col-12">
                                <h5 className="fs-5 mb-4">Identity:</h5>
                            </div>
                            <div className="row m-0 px-5">
                                <div className="col-4">
                                    <div className="inputBox mb-3">
                                        <label htmlFor="">Amount</label>
                                        <input type="number" className="w-100" autoComplete="off" name="amount" placeholder=""
                                            ref={userRef} onChange={(e) => setAmount(e.target.value)}
                                            value={amount} required />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="inputBox mb-3">
                                        <label htmlFor="">Pourcent</label>
                                        <input type="number" className="w-100" autoComplete="off" name="pourcent" placeholder=""
                                            ref={userRef} onChange={(e) => setPourcent(e.target.value)} value={pourcent} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="inputBox mb-3">
                                        <label htmlFor="">Currency</label>
                                        <select className="form-select" name="current" aria-label="Default select example"
                                            ref={userRef} onChange={(e) => setCurrent(e.target.value)} value={current}>
                                            <option value="">Choose currency</option>
                                            {options.map((option, i) => (
                                                <option key={i} value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 px-5">
                            <div className="col-4">
                                <div className="inputBox mb-3">
                                    <label htmlFor="">Choose Country</label>
                                    <select className="form-select" name="country" aria-label="Default select example"
                                    ref={userRef} onChange={(e) => setCountry(e.target.value)} value={country}>
                                          <option value="">Choose country</option>
                                        {listCountri.map((country, i) => (
                                            <option key={i} value={country.id}>{country.name}</option>
                                        ))
                                        }
                                    </select>
                                </div>
                            </div>
                                <div className="col-4">
                                    <div className="inputBox mb-3">
                                        <label htmlFor="">Date Start</label>
                                        <input type="date" name="datestart" className="w-100"
                                            ref={userRef} onChange={(e) => setDatestart(e.target.value)} value={datestart} />
                                        {/* </select> */}
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="inputBox mb-3">
                                        <label htmlFor="">Date End</label>
                                        <input type="date" name="dateend" className="w-100"
                                            ref={userRef} onChange={(e) => setDateend(e.target.value)} value={dateend} />
                                        {/* </select> */}
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="inputBox mb-3">
                                        <label htmlFor="">Date End</label>
                                        <input type="file" name="dateend" className="w-100"
                                             />
                                        {/* </select> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-1 mt-3 ms-auto me-5">
                                <button type="button" onClick={AddNewinvestor} className="btn w-100 btn-primary rounded-pill">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </>
    );

}

export default Investiment;