import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { TablePagination } from '@mui/material';
import {CssBaseline } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
function Row(props) {
    const row = props.row;
    const index = props.index;
    const [open, setOpen] = React.useState(false);

    const getUnpaid = (x) => {
        if (x <= 0) return 'text-danger';
        if (x > 0) return 'fw-bold';
        return '';
    }
    const getPaid = (x) => {
        if (x <= 0) return 'Unpaid';
        if (x > 0) return 'Paid';
        return '';
    }
    return (

        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
                <TableCell>
                    {index + 1}
                </TableCell>
                <TableCell >{row.fullname}</TableCell>
                <TableCell >{row.source}</TableCell>
                <TableCell >{row.date}</TableCell>
                <TableCell >{row.amount}</TableCell>
                <TableCell className={getUnpaid(row.amount)}>{getPaid(row.amount)}</TableCell>
                <TableCell >
                    <IconButton>
                        Show Distribution
                    </IconButton>

                </TableCell>
                <TableCell >{row.reference}</TableCell>
                <TableCell >
                    <div className="d-flex">
                        <button className="btn shadow-btn" type="button" data-bs-toggle="modal" data-bs-target="#editData"><span>Edit</span><i className="fa-regular fa-pen-to-square"></i></button>
                        <button className="btn shadow-btn ms-3"><span>Delete</span><i className="fa-solid fa-trash-can"></i></button>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Distribution
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>name</TableCell>
                                        <TableCell align="right">Amount ($)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.distibution.map((dist, i) => (
                                        <TableRow key={dist.name}>
                                            <TableCell component="th">{i + 1}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {dist.name}
                                            </TableCell>
                                            <TableCell align="right">{dist.amount}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function CollapsibleTable(props) {
    const { rows } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <Paper sx={{ width: '100%' }}>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table" className="table  resizable">
                    <TableHead>
                        <TableRow>
                            <TableCell scope="col">#</TableCell>
                            <TableCell scope="col">Fullname</TableCell>
                            <TableCell scope="col">Payment Source</TableCell>
                            <TableCell scope="col">Date-time</TableCell>
                            <TableCell scope="col">Amount</TableCell>
                            <TableCell scope="col">Status</TableCell>
                            <TableCell scope="col">Parteners Distribution</TableCell>
                            <TableCell scope="col">Verified</TableCell>
                            <TableCell scope="col">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, i) => (
                                <Row key={row.id} row={row} index={i} />
                            ))}
                    </TableBody>
                </Table>
                <TablePagination 
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {/* <div>
                    <Pagination 
                      count={10} 
                      color="secondary"
                      variant="outlined"
                      
                    />
                </div> */}
            </TableContainer>

        </Paper>
    );
}