import React, { useState, useEffect, useRef, useContext } from 'react';

import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthContext from "./login/context/AunthLogin";
import '../assets/css/bootstrap.min.css';
import '../assets/css/amsify.select.css';
import '../assets/css/style.css';
import '../assets/css/color.css';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AlertTitle from '@mui/material/AlertTitle';
import { ToastContainer, toast } from "react-toastify";

function Investor() {

    var data = '';
    const { setAuth } = useContext(AuthContext);

    const [listCountri, setListCountri] = useState([]);
    const [listInvestor, setListInvestor] = useState([])
    const [amount, setAmount] = useState('');
    const [pourcent, setPourcent] = useState('');
    const [current, setCurrent] = useState('');
    const [datestart, setDatestart] = useState('');
    const [dateend, setDateend] = useState('');
    const [country, setCountry] = useState('');
    const [InveId, setInveId] = useState(null);
    const [Idcusto, setIdCusto] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const userRef = useRef();
    const errRef = useRef();

    useEffect(() => {
        List();
        countri();
    }, []);

    // Traitements 
    const List = () => {
        var config = {
            method: 'get',
            url: global.Config.api.url + '/investor/investiment/load',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + global.Config.api.token
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setListInvestor(response.data.data);
                // setListCountri(response.data.list)
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    //contry
    const countri = () => {
        var config = {
            method: 'get',
            url: global.Config.api.url + '/configuration/country/load',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + global.Config.api.token
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setListCountri(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    function deleteInvestor(id) {
        var config = {
            method: 'delete',
            url: global.Config.api.url + '/investor/investiment/delete/' + id,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + global.Config.api.token
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                if (response.data.status == 200) {
                    List();
                    toast.success(`Your deleted success`);
                } else {
                    toast.error(`Echec to delete this Investiment`)
                }
            })
            .catch(function (error) {
                toast.error(error.message);
            });
    }

    function updateInvestiment(id) {
        var data = JSON.stringify({
            "id_country": country,
            "id_customer": Idcusto,
            "pourcent": pourcent,
            "amount": amount,
            "currency": current,
            "date_start": datestart,
            "date_end": dateend,
            "contrat_file": "file.pdf"
        });

        var config = {
            method: 'put',
            url: global.Config.api.url + '/investor/investiment/update/' + id,

            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + global.Config.api.token,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                if (response.data.status == 200) {
                    const accessToken = response.data.accessToken;
                    const roles = response.data.roles;
                    setAuth({ amount, pourcent, current, datestart, dateend, accessToken, roles })
                    setAmount('');
                    setPourcent('');
                    setCurrent('');
                    setDatestart('');
                    setDateend('');
                    setSuccess(true);
                    List();
                    toast.success(`Udpdate success`);
                    setErrMsg('');
                } else {
                    // setErrMsg(response.data.message);
                    toast.error(`Echec, please verify elements after submit`);
                }

            })
            .catch(function (error) {
                toast.error(error.response.data);
            });
    }
    //End traitements

    //updateStatus
    function updateStatus(id) {
        const getInvestStaus = (array) => {
            var inv_status = '';
            array.forEach(element => {
                if (element.invest_status == 1) inv_status = 'inactive';
                if (element.invest_status == 0) inv_status = 'active';
            });

            return inv_status;
        }
        var data = JSON.stringify({
            "id_investement": id,
            "status": getInvestStaus(listInvestor)
        });

        var config = {
            method: 'put',
            url: global.Config.api.url + '/investor/investiment/status',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + global.Config.api.token,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                if (response.data.status == 200) {
                    List();
                    toast.success(`Success in granting access`);
                } else {
                    toast.error(`Failure to grant access`)
                }
            })
            .catch(function (error) {
                toast.error(error.message)
            });
    }
    //End update

    function InvestorIsShow(id){
        const getInvestVisible = (array) => {
            var inv_status = '';
            array.forEach(element => {
                if (element.isShow == 1) inv_status = 'hide';
                if (element.isShow == 0) inv_status = 'show';
            });

            return inv_status;
        }
        var data = JSON.stringify({
            "id_investement": id,
            "status": getInvestVisible(listInvestor)
          });
          
          var config = {
            method: 'put',
            url: global.Config.api.url +'/investor/investiment/visible',
            headers: { 
              'Accept': 'application/json', 
              'Authorization': 'Bearer '+ global.Config.api.token, 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            if (response.data.status == 200) {
                List();
                toast.success(`Success in granting access`);
            } else {
                toast.error(`Failure to grant access`)
            }
          })
          .catch(function (error) {
            toast.error(error.message);
          });
    }

    //get dialog
    const [open, setOpen] = React.useState(false);
    const [openalert, setopenalert] = React.useState(false);

    const handleClickOpen = (id) => {
        setOpen(true);

        listInvestor.forEach(element => {
            if (element.id == id) {
                setAmount(element.amount);
                setPourcent(element.pourcent);
                setCurrent(element.currency);
                setDatestart(element.date_start);
                setDateend(element.date_and);
                setCountry(element.country);
                setInveId(element.id);
                setIdCusto(element.id_customer);
            }
        });

    };

    const handleClickAlert = (id) => {
        setopenalert(true);
        listInvestor.forEach(element => {
            if (element.id == id) {
                setInveId(element.id);
            }
        });
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseAlert = () => {
        setopenalert(false);
    };

    const options = [
        {
            label: 'USD',
            value: 'USD'
        }
        // {
        //     label: 'CDF',
        //     value: 'CDF'
        // }
    ]

    const getInvestStaus = (x) => {
        if (x == 1) return 'inactive';
        if (x == 0) return 'active';
        return '';
    }

    const getIsshow = (x) => {
        if (x == 1) return 'Hide';
        if (x == 0) return 'IsShow';
        return '';
    }

    return (
        <>
            <div className="mainDashboard">
                <div className="shadowBox"></div>
                <div className="container-fluid px-0">

                    {/* Table Header Bar */}
                    <div className="row m-0 px-4 defaultPadding">
                        <div className="col-5">
                            <div className="pagesHeading d-flex">
                                <div className="pageIcon item-center bgDepartment">
                                    <img src="../assets/img/department.svg" alt="" />
                                </div>
                                <div className="my-auto ms-3 d-flex">
                                    <div>
                                        <span className="small-font">Key Investors</span>
                                        <span className="tableHeading d-flex position-relative">
                                            Investors
                                            <div className="dropdown categorydrop">
                                                <button className="btn p-0" type="button" id="categoryMenu" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-caret-down ms-3 fs-5"></i></button>
                                                <ul className="dropdown-menu" aria-labelledby="categoryMenu">
                                                    <h5>Key Investors Categories</h5>
                                                    <li><Link className="dropdown-item" to="/wallet">Wallet</Link></li>
                                                    <li><Link className="dropdown-item active" to="/investors">Investors</Link></li>
                                                </ul>
                                            </div>
                                        </span>
                                    </div>
                                    <div className="mt-3 ms-4">
                                        <button className="btn shadow-btn iconBtn item-center bg-white"><img src="../assets/img/pin.svg" alt="" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-7">
                            <div className="d-flex mt-3 justify-content-end">
                                <div className="">
                                    <Link className="btn shadow-btn px-3 bg-white small-font" type="button" to="/addInvestors"><img src="../assets/img/addbtn.svg" className="me-2" alt="" />Add Investors</Link>
                                </div>
                                <div className="findBox bg-white ms-3">
                                    <form action="">
                                        <button className="btn"><i className="fa-solid fa-magnifying-glass"></i></button>
                                        <input type="text" placeholder="Search Here...." />
                                    </form>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><i className="fa-solid fa-gear"></i><i className="fa-solid fa-caret-down ms-3"></i></button>
                                </div>
                                <div className="ms-3 dropdown categorydrop">
                                    <button className="btn shadow-btn h-100 bg-white" id="categoryMenu" data-bs-toggle="dropdown"><i className="fa-solid fa-list"></i><i className="fa-solid fa-caret-down ms-3"></i></button>
                                       <ul className="dropdown-menu" aria-labelledby="categoryMenu">
                                        <li><button className="dropdown-item " variant="outlined">IsShow</button></li>
                                        <li><button className="dropdown-item" variant="outlined">Hide</button></li>
                                    </ul>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><i className="fa-solid fa-rotate-right"></i></button>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><i className="fa-regular fa-pen-to-square"></i></button>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><img src="../assets/img/data.svg" alt="" /></button>
                                </div>
                                <div className="ms-3">
                                    <button className="btn shadow-btn h-100 bg-white"><img src="../assets/img/solar.svg" alt="" /></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Table Box  */}
                    <div className="row m-0">
                        < ToastContainer />
                        <div className="col-12 px-0">
                            <table className="table secondTable resizable">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Pourcent</th>
                                        <th scope="col">Contract</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listInvestor.map((investors, i) => (
                                        <tr key={i}>
                                            <th scope="row">0{i + 1}</th>
                                            <td>{investors.name}</td>
                                            <td>{investors.email}</td>
                                            <td>{investors.amount}</td>
                                            <td>{investors.pourcent}%</td>
                                            <td>contract</td>
                                            <td>
                                                <div >
                                                    <div>
                                                        <span className=" d-flex position-relative">

                                                            <div className="dropdown categorydrop">
                                                                <button className="btn shadow-btn h-100 bg-white" type="button" id="categoryMenu" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-list"></i><i className="fa-solid fa-caret-down ms-3"></i></button>
                                                                {/* <button className="btn shadow-btn h-100 bg-white" type="button" id="categoryMenu" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-caret-down ms-3 fs-5"></i></button> */}
                                                                <ul className="dropdown-menu" aria-labelledby="categoryMenu">

                                                                    <li><Link className="dropdown-item" variant="outlined" onClick={() => handleClickOpen(investors.id)}>Edit Investiment</Link></li>
                                                                    <li><Link to={`/updateInvestors/${investors.id_customer}`} className="dropdown-item" variant="outlined">Edit Investor</Link></li>
                                                                    <li><Link className="dropdown-item" onClick={() => handleClickAlert(investors.id)} >Delete</Link></li>
                                                                    <li><button onClick={() => updateStatus(investors.id)} className="dropdown-item btn-danger active" variant="outlined">{getInvestStaus(investors.invest_status)}</button></li>
                                                                    <li><button onClick={() => InvestorIsShow(investors.id)} className="dropdown-item  active" variant="outlined">{getIsshow(investors.isShow)}</button></li>
                                                                </ul>

                                                            </div>
                                                        </span>

                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        
                    </div>

                </div>
            </div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Modify</DialogTitle>
                <DialogContent>
                    <div>

                        < ToastContainer />
                    </div>
                    <div className="row m-0 px-12">
                        <div className="col-6">
                            <div className="inputBox mb-3">
                                <label htmlFor="">Amount</label>
                                <input type="number" className="w-100" name="amount" placeholder=""
                                    value={amount} onChange={(e) => { setAmount(e.target.value) }}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="inputBox mb-3">
                                <label htmlFor="">Pourcent</label>
                                <input type="number" className="w-100" name="pourcent" placeholder=""
                                    value={pourcent} onChange={(e) => { setPourcent(e.target.value); }}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="row m-0 px-12">
                        <div className="col-6">
                            <div className="inputBox mb-3">
                                <label htmlFor="">Currency</label>
                                <select className="form-select" name="current" aria-label="Default select example"
                                    value={current} onChange={(e) => { setCurrent(e.target.value) }}
                                >
                                    <option value="">Choose currency</option>
                                    {options.map((option, i) => (
                                        <option key={i} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="inputBox mb-3">
                                <label htmlFor="">Choose Country</label>
                                <select className="form-select" name="country" aria-label="Default select example"
                                    value={country} onChange={(e) => { setCountry(e.target.value) }}
                                >
                                    <option value="">Choose country</option>
                                    {listCountri.map((countri, i) => (
                                        <option key={i} value={countri.id}>{countri.name}</option>
                                    ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 px-12">
                        <div className="col-6">
                            <div className="inputBox mb-3">
                                <label htmlFor="">Date Start</label>
                                <input type="date" name="datestart" className="w-100"
                                    value={(datestart.substring(0, 10))} onChange={(e) => { setDatestart(e.target.value) }}
                                />
                                {/* </select> */}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="inputBox mb-3">
                                <label htmlFor="">Date End</label>
                                <input type="date" name="dateend" className="w-100"
                                    value={(dateend).substring(0, 10)} onChange={(e) => { setDateend(e.target.value) }}
                                />
                                {/* </select> */}
                            </div>
                        </div>
                        <input type="hidden" name="InveId" value={InveId} onChange={(e) => { setInveId(e.target.value) }} />
                        <input type="hidden" name="Idcusto" value={Idcusto} onChange={(e) => { setIdCusto(e.target.value) }} />
                    </div>
                    <div className="row m-0 px-12">
                        <div className="col-6">
                            <div className="inputBox mt-5">
                                <input type="file" name="dateend" className="w-100"
                                />
                                {/* </select> */}
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="inputBox mt-5">
                                <label htmlFor=""></label>
                                <button type="button" onClick={() => updateInvestiment(InveId)} className="btn w-100 btn-primary rounded-pill">Submit</button>
                            </div>
                        </div>

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Exit</Button>

                </DialogActions>
            </Dialog>

            <div>

                <Dialog
                    open={openalert}
                    onClose={handleCloseAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity="error">
                                    < ToastContainer className="btn-success" />
                                    <AlertTitle>Alert</AlertTitle>
                                    Are you sure to want — <strong>delete</strong> this investiment ?

                                    <div className="inputBox mt-5">
                                        <label htmlFor=""></label>
                                        <input type="hidden" name="InveId" value={InveId} onChange={(e) => { setInveId(e.target.value) }} />
                                        <button type="button" onClick={() => deleteInvestor(InveId)} className="btn w-100 btn-danger rounded-pill">Delete</button>
                                    </div>
                                </Alert>
                            </Stack>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleCloseAlert} autoFocus>
                            Exit
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}

export default Investor